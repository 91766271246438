import React, { useEffect, useState } from 'react'
import { Select as SelectMUI } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

interface ISelectRequired {
	labelId: string
	id: string
	value: string
	defaultValue: string
	onChange: (event: SelectChangeEvent) => void
	children: React.ReactNode
}

interface ISelectOptional {
	disabled?: boolean
}

interface ISelect extends ISelectRequired, ISelectOptional {}

const defaultProps: ISelectOptional = {
	disabled: false,
}

const Select: React.FC<ISelect> = ({
	labelId,
	id,
	value,
	defaultValue,
	onChange,
	children,
	disabled,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const onClose = () => {
		setIsOpen(false)
	}

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('scroll', onClose)
		}

		return () => {
			document.removeEventListener('scroll', onClose)
		}
	}, [isOpen])

	return (
		<SelectMUI
			open={isOpen}
			onClick={() => setIsOpen(!isOpen)}
			labelId={labelId}
			id={id}
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			MenuProps={{
				TransitionProps: {
					exit: false,
				},
			}}
			disabled={disabled}
		>
			{children}
		</SelectMUI>
	)
}

Select.defaultProps = defaultProps

export default React.memo(Select)
