import React from 'react'
import ExtraSmallParagraph from 'share/ExtraSmallParagraph'
import SemiBoldParagraph from 'share/SemiBoldParagraph'

import './index.scss'

interface IParagraphGroupProps {
	h3?: string | null
	semiBoldParagraph?: React.ReactNode | string | null
	extraSmallParagraph?: React.ReactNode | string | null
	children?: React.ReactNode | null
	className?: string
}

const defaultProps: IParagraphGroupProps = {
	h3: null,
	semiBoldParagraph: null,
	extraSmallParagraph: null,
	children: null,
	className: '',
}

const ParagraphGroup: React.FC<IParagraphGroupProps> = ({
	h3 = null,
	semiBoldParagraph = null,
	extraSmallParagraph = null,
	children,
	className = '',
}) => (
	<section className={`paragraph-group__wrapper ${className}`}>
		{h3
			? (
				<h3 className={semiBoldParagraph || extraSmallParagraph ? 'm-b-quarter' : ''}>
					{h3}
				</h3>
			) : null
		}

		{semiBoldParagraph ? (
			<SemiBoldParagraph>{semiBoldParagraph}</SemiBoldParagraph>
		) : null}

		{extraSmallParagraph ? (
			<ExtraSmallParagraph>{extraSmallParagraph}</ExtraSmallParagraph>
		) : null}

		{children}
	</section>
)

ParagraphGroup.defaultProps = defaultProps

export default ParagraphGroup
