import React from 'react'

import { Button } from 'share'
import { SvgIconComponent } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import './index.scss'

interface IButtonOptional {
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	buttonStyle?: string
	iconSize?: number
	children?: React.ReactNode
	Icon?: SvgIconComponent | null
	tabIndex?: number
	tooltipTitle?: string
	tooltipPlacement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';
}

const defaultProps: IButtonOptional = {
	buttonStyle: 'without-uppercase',
	iconSize: 18,
	tabIndex: 0,
	onClick: undefined,
	children: undefined,
	Icon: null,
	tooltipTitle: undefined,
	tooltipPlacement: 'top',
}

type IButtonProps = IButtonOptional

const ButtonIconOptional: React.FC<IButtonProps> = ({
	buttonStyle = 'without-uppercase',
	iconSize = 18,
	onClick,
	children,
	Icon,
	tabIndex,
	tooltipTitle,
	tooltipPlacement,
}) => {
	const icon = Icon ? (
		<Icon
			sx={{
				marginLeft: children ? '5px' : 0,
				fontSize: iconSize,
				width: iconSize,
				height: iconSize,
			}}
		/>
	) : null

	return (
		<Button
			type="button"
			variant="text"
			buttonStyle={buttonStyle}
			onClick={onClick}
			tabIndex={tabIndex}
		>
			{children}
			{icon
				? tooltipTitle
					? (
						<Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
							{icon}
						</Tooltip>
					)
					: icon
				: null}
		</Button>
	)
}

ButtonIconOptional.defaultProps = defaultProps

export default React.memo(ButtonIconOptional)
