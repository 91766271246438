// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main{position:relative;display:flex;margin:auto;padding:32px 0}`, "",{"version":3,"sources":["webpack://./src/share/MainWrapper/index.scss"],"names":[],"mappings":"AAEA,MACC,iBAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA","sourcesContent":["@import 'styles/index.scss';\n\n.main {\n\tposition: relative;\n\tdisplay: flex;\n\tmargin: auto;\n\tpadding: 32px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
