import React, { useEffect, useState } from 'react'
import { IconButton, InputBaseComponentProps, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import useSwitchState from 'components/hooks/useSwitchState'

import './index.scss'

interface IInputRequired {
	value: string
	onChange: (value: string) => void
}

interface IInputOptional {
	label?: string | React.ReactNode
	type?: string
	className?: string
	placeholder?: string
	onBlur?: (value: string) => void
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => string
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
	isCustomUnderLine?: boolean
	pattern?: RegExp | string | null
	inputProps?: InputBaseComponentProps
}

const defaultProps: IInputOptional = {
	label: '',
	type: 'text',
	className: '',
	placeholder: '',
	onBlur: undefined,
	onPaste: undefined,
	onKeyDown: undefined,
	isCustomUnderLine: false,
	pattern: null,
	inputProps: {},
}

interface IInputProps extends IInputRequired, IInputOptional {}

const textFieldVariant = 'standard'

const defaultUnderLine = '1px solid #d5d5dc' // $black-20-color
const boldUnderLine = '2px solid #36353c' // $black-70-color

const Input: React.FC<IInputProps> = ({
	value,
	onChange,
	type = 'text',
	label = '',
	className = '',
	placeholder = '',
	onBlur = undefined,
	onPaste = undefined,
	onKeyDown = undefined,
	isCustomUnderLine = false,
	pattern = null,
	inputProps = {},
}) => {
	const [inputValue, setInputValue] = useState(value)
	const [isShowPassword, setIsShowPassword] = useSwitchState(false)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = event.target.value

		if (pattern && targetValue.length) {
			const regExp = new RegExp(pattern)

			targetValue = regExp.test(targetValue) ? targetValue : inputValue
		}

		onChange(targetValue)
		setInputValue(targetValue)
	}

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let targetValue = event.target.value

		// remove last dot for decimal input mode
		if (inputProps.inputMode === 'decimal') {
			targetValue = targetValue.replace(/\.$/, '')
		}

		if (typeof onBlur === 'function') {
			onBlur(targetValue)
		}
		onChange(targetValue)
		setInputValue(targetValue)
	}

	const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		if (onPaste) {
			const targetValue = onPaste(event)

			setInputValue(targetValue)
		}
	}

	useEffect(() => {
		setInputValue(value)
	}, [value])

	const endAdornmentIcon =
		type === 'password' ? (
			<IconButton onClick={setIsShowPassword} tabIndex={-1}>
				{isShowPassword ? <Visibility /> : <VisibilityOff />}
			</IconButton>
		) : null

	const inputType = type === 'password' && isShowPassword ? 'text' : type

	return (
		<TextField
			variant={textFieldVariant}
			type={inputType}
			label={label}
			value={inputValue}
			onChange={handleChange}
			onBlur={handleBlur}
			className={className}
			placeholder={placeholder}
			onPaste={handleOnPaste}
			onKeyDown={onKeyDown}
			InputProps={{
				inputProps,
				endAdornment: endAdornmentIcon,
			}}
			sx={{
				'.MuiInput-underline': {
					'&::before': {
						borderBottom: isCustomUnderLine ? boldUnderLine : defaultUnderLine,
					},
				},
			}}
		/>
	)
}

Input.defaultProps = defaultProps

export default React.memo(Input)
