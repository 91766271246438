import React from 'react'

import {
	Avatar as AvatarMUI,
} from '@mui/material'
import { getFirstChar } from 'utils/userUtils'

import './index.scss'

interface IAvatarProps {
	name: string
	avatar: null | string | undefined
	size?: number
	fontSize?: number
}

const defaultProps = {
	size: 24,
	fontSize: 16,
}

const Avatar: React.FC<IAvatarProps> = ({
	name,
	avatar,
	size = 24,
	fontSize = 16,
}) => avatar ? (
	<img
		width={size}
		height={size}
		src={avatar}
		alt={`${name}'s avatar`}
		className="avatar"
	/>
) : (
	<AvatarMUI sx={{ width: size, height: size, fontSize }}>
		{getFirstChar(name)}
	</AvatarMUI>
)

Avatar.defaultProps = defaultProps

export default Avatar
