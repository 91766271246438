import React from 'react'
import { ErrorBoundary } from 'react-error-boundary';

import { Outlet } from 'react-router'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/selectors'

import Header from 'components/Header'
import Help from 'components/Help'
import Onboarding from 'components/Onboarding'

import './index.scss'
import ErrorFallback from 'share/ErrorFallback';

const MainWrapper = () => {
	const { user } = useSelector(selectUser)

	return user.role !== '' ? (
		<>
			<Header />

			<main className="main">
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Outlet />
				</ErrorBoundary>

				{user.is_welcome_discarded ? null : (
					<Onboarding />
				)}
				<Help />
			</main>
		</>
	) : (
		<span className="loader" />
	)
}

export default MainWrapper
