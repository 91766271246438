import React from 'react'
import { SemiBoldParagraph } from 'share'

import { ITransactionDetailsMapper } from './helpers/createTransactionDetails'

import './index.scss'

interface ITransactionDetails {
	transactionDetailsMapper: ITransactionDetailsMapper
}
const TransactionDetails: React.FC<ITransactionDetails> = ({
	transactionDetailsMapper,
}) => (
	<ul className="transaction-details__wrapper">
		{transactionDetailsMapper.map(({ title, detail }) => (
			<li key={title} className="transaction-details__item">
				<span className="transaction-details__item__title">{title}</span>
				<SemiBoldParagraph className="transaction-details__item__detail">
					{detail}
				</SemiBoldParagraph>
			</li>
		))}
	</ul>
)

export default TransactionDetails
