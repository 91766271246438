import React from 'react'
import { useDispatch } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import { resendUserInviteRequest } from 'redux/user/reducer'

import { Button } from 'share'

const { MARGIN_LEFT, SECONDARY, SMALL_H, CONTAINED } = buttonSettings

interface IResendInviteButtonRequired {
	children: React.ReactNode
	email: string
}

interface IResendInviteButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IResendInviteButtonOptional = {
	buttonStyle: `${SECONDARY} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IResendInviteButton
	extends IResendInviteButtonRequired,
		IResendInviteButtonOptional {}

const ResendInviteButton: React.FC<IResendInviteButton> = ({
	children,
	email,
	buttonStyle = `${SECONDARY} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const onClick = () => {
		dispatch(
			resendUserInviteRequest({
				email,
			})
		)
	}

	return (
		<Button
			type="button"
			buttonStyle={buttonStyle}
			variant={variant}
			onClick={onClick}
		>
			{children}
		</Button>
	)
}

ResendInviteButton.defaultProps = defaultProps

export default React.memo(ResendInviteButton)
