import { OpenInNew } from '@mui/icons-material'
import buttonSettings from 'constants/buttonSettings'
import React from 'react'
import { ProtocolEnumType } from 'redux/invoice/types'

import { Button } from 'share'
import { getTransactionSiteByProtocol } from 'utils/wallets'

const { SECONDARY, MEDIUM_H, CONTAINED, MARGIN_LEFT, MARGIN_TOP } =
	buttonSettings

interface IViewOnExplorerRequired {
	children: React.ReactNode
	transactionHash: null | string
}

interface IViewOnExplorerOptional {
	protocol?: ProtocolEnumType
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IViewOnExplorerOptional = {
	protocol: ProtocolEnumType.NEAR,
	buttonStyle: `${SECONDARY} ${MEDIUM_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IViewOnExplorer
	extends IViewOnExplorerRequired,
		IViewOnExplorerOptional {}

const ViewOnExplorer: React.FC<IViewOnExplorer> = ({
	children,
	protocol = ProtocolEnumType.NEAR,
	transactionHash,
	buttonStyle = `${SECONDARY} ${MEDIUM_H} ${MARGIN_LEFT} ${MARGIN_TOP}`,
	variant = CONTAINED,
}) => (
	<a
		href={`${getTransactionSiteByProtocol(protocol)}${transactionHash}`}
		target="_blank"
		rel="noreferrer"
	>
		<Button type="button" buttonStyle={buttonStyle} variant={variant}>
			{children}&nbsp;
			<OpenInNew fontSize="small" />
		</Button>
	</a>
)

ViewOnExplorer.defaultProps = defaultProps

export default React.memo(ViewOnExplorer)
