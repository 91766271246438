import React, { useEffect } from 'react'
import { Popover as PopoverMUI, PopoverOrigin } from '@mui/material'

import './index.scss'

interface IPopover {
	open: boolean
	showArrow: boolean
	anchorOrigin: PopoverOrigin
	transformOrigin: PopoverOrigin
	onClosePopover: () => void
	anchorEl: HTMLButtonElement | null
	children: React.ReactNode
	className?: string
}

const Popover: React.FC<IPopover> = ({
	open,
	showArrow,
	onClosePopover,
	anchorEl,
	children,
	anchorOrigin,
	transformOrigin,
	className = '',
}) => {
	useEffect(() => {
		if (open) {
			document.addEventListener('scroll', onClosePopover)
		}

		return () => {
			document.removeEventListener('scroll', onClosePopover)
		}
	}, [open])

	return (
		<PopoverMUI
			className={className}
			disableScrollLock
			disableRestoreFocus
			open={open}
			onClose={onClosePopover}
			anchorEl={anchorEl}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			TransitionProps={{ exit: false }}
		>
			{showArrow ? <div className="popover-content__arrow"/> : null}
			<div className="popover-content">{children}</div>
		</PopoverMUI>
	)
}

Popover.defaultProps = {
	className: '',
}

export default React.memo(Popover)
