import React from 'react';
import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import buttonSettings from 'constants/buttonSettings'
import { IInvoice } from 'redux/invoice/types'
import { Archive, CopyAll, DeleteForever, Edit, Unarchive } from '@mui/icons-material'
import { DeleteInvoiceButton, Popover, ArchiveInvoiceButton, CloneInvoiceButton, EditInvoiceButton } from 'share'
import { useSelector } from 'react-redux'
import { selectInvoices } from 'redux/invoices/selectors'
import { selectUser } from 'redux/user/selectors'
import { isInvoiceEditable } from 'utils/invoiceUtils'

const { ROUNDED, SMALL_H, CONTAINED, TEXT, MARGIN_TOP, MARGIN_LEFT } = buttonSettings

interface IInvoiceActionMenu {
	invoice: IInvoice
}

const InvoiceActionMenu: React.FC<IInvoiceActionMenu> = ({
	invoice
}) => {
	const {
		filters,
	} = useSelector(selectInvoices)
	const { user, userTeamRoles } = useSelector(selectUser)

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				className={`${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`}
				variant={`${CONTAINED}`}
				onClick={handleClick}
				disableRipple
			>
				<MoreHorizIcon />
			</Button>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorEl={anchorEl}
				open={open}
				onClosePopover={handleClose}
				showArrow={false}
				className="m-t-quarter"
			>
				<div className="p-b-half p-l-half p-r flex-column align-items-start">
					{['paid', 'closed', 'void'].includes(invoice?.status || '') ? (
						<CloneInvoiceButton
							buttonStyle={`${MARGIN_TOP} without-uppercase`}
							variant={TEXT}
							invoiceIds={[invoice.id]}
						>
							<CopyAll className="m-r-half" />
							Duplicate
						</CloneInvoiceButton>
					) : null}
					{['pending', 'readyToPay', 'inReview']
						.includes(invoice.status) && !invoice.created_on_blockchain && isInvoiceEditable(invoice, user, userTeamRoles) ? (
						<EditInvoiceButton
							buttonStyle={`${MARGIN_TOP} without-uppercase`}
							variant={TEXT}
							invoice={invoice}
						>
							<Edit className="m-r-half" />
							Edit
						</EditInvoiceButton>
					) : null}
					{isInvoiceEditable(invoice, user, userTeamRoles)
					&& ['draft', 'pending', 'readyToPay', 'inReview'].includes(invoice.status) ? (
						<DeleteInvoiceButton
							buttonStyle={`${MARGIN_TOP} without-uppercase`}
							variant={TEXT}
							invoices={[invoice]}
						>
							<DeleteForever className="m-r-half" />
							Delete
						</DeleteInvoiceButton>
					) : null}
					<ArchiveInvoiceButton
						buttonStyle={`${MARGIN_TOP} without-uppercase`}
						variant={TEXT}
						invoiceIds={[invoice.id]}
						archive={!filters.archive}
					>
						{filters.archive ? (
							<>
								<Unarchive className="m-r-half" />
								Unarchive
							</>
						) : (
							<>
								<Archive className="m-r-half" />
								Archive
							</>
						)}
					</ArchiveInvoiceButton>
				</div>
			</Popover>
		</>
	);
}

export default InvoiceActionMenu
