import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import './index.scss'

interface IArrowForwardRequired {
	isActive: boolean
}

interface IArrowForwardOptional {
	className?: string
}

interface IArrowForwardProps
	extends IArrowForwardOptional,
		IArrowForwardRequired {}

const defaultProps: IArrowForwardOptional = {
	className: '',
}

const ArrowForward: React.FC<IArrowForwardProps> = ({
	isActive,
	className,
}) => (
	<ArrowForwardIosIcon
		className={`${className} arrow-forward ${
			isActive ? 'arrow-forward_active' : ''
		}`}
	/>
)

ArrowForward.defaultProps = defaultProps

export default ArrowForward
