import React from 'react'

import './index.scss'

interface ISemiBoldParagraphRequired {
	children: React.ReactNode
}

interface ISemiBoldParagraphOptional {
	className?: string
}

const defaultProps = {
	className: '',
}

interface ISemiBoldParagraphProps
	extends ISemiBoldParagraphRequired,
		ISemiBoldParagraphOptional {}

const SemiBoldParagraph: React.FC<ISemiBoldParagraphProps> = ({
	children,
	className = '',
}) => (
	<p className={`semi-bold-paragraph ${className}`} tabIndex={-1}>
		{children}
	</p>
)

SemiBoldParagraph.defaultProps = defaultProps

export default SemiBoldParagraph
