import React from 'react'

import buttonSettings from 'constants/buttonSettings'
import { useDispatch, useSelector } from 'react-redux'
import { getInvoiceReportsRequest } from 'redux/invoiceReports/reducer'
import { selectInvoiceReports } from 'redux/invoiceReports/selectors'
import { sendGtagEvent } from 'utils/userUtils'
import { selectUser } from 'redux/user/selectors'

import { Button } from 'share'

const { PRIMARY, MEDIUM_H, CONTAINED, MARGIN_RIGHT } = buttonSettings

interface IGenerateCsvButtonRequired {
	children: React.ReactNode
}

interface IGenerateCsvButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IGenerateCsvButtonOptional = {
	buttonStyle: `${PRIMARY} ${MEDIUM_H} ${MARGIN_RIGHT}`,
	variant: CONTAINED,
}

interface IGenerateCsvButton
	extends IGenerateCsvButtonRequired,
		IGenerateCsvButtonOptional {}

const GenerateCsvButton: React.FC<IGenerateCsvButton> = ({
	children,
	buttonStyle = `${PRIMARY} ${MEDIUM_H} ${MARGIN_RIGHT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const { isGenerateCsvLoading } = useSelector(selectInvoiceReports)
	const { user } = useSelector(selectUser)

	const onClick = () => {
		dispatch(
			getInvoiceReportsRequest({
				filters: {},
				generateCsv: true,
			})
		)

		sendGtagEvent('report_export', {
			role: user.role,
		})
	}

	return (
		<Button
			type="button"
			buttonStyle={buttonStyle}
			variant={variant}
			onClick={onClick}
			disabled={isGenerateCsvLoading}
		>
			{children}
		</Button>
	)
}

GenerateCsvButton.defaultProps = defaultProps

export default React.memo(GenerateCsvButton)
