// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-pending__note .transaction-important{font-size:inherit;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/share/TransactionProgress/index.scss"],"names":[],"mappings":"AAGE,kDACE,iBAAA,CACA,eAAA","sourcesContent":["@import 'styles/index.scss';\n\n.transaction-pending__note {\n  & .transaction-important {\n    font-size: inherit;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
