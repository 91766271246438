import { Clear } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import {
	IUpdateInvoiceTransactionDetails,
	ProtocolEnumType,
} from 'redux/invoice/types'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types';

import H2 from 'share/H2'
import { ExtraSmallParagraph } from 'share/index'
import Modal from 'share/Modal'

import ViewOnExplorer from 'share/ViewOnExplorer'
import { tokenSymbols } from 'utils/invoiceUtils'

import TransactionDetails from 'share/TransactionDetails'

import { createTransactionDetails } from 'share/TransactionDetails/helpers/createTransactionDetails'

import './index.scss'

interface ITransactionSuccessRequired {
	transactionTitles: Array<{ title: string; key: string }>
	transactionDetails: { [key: string]: string | React.ReactNode }
}

interface ITransactionSuccessOptional {
	transactionHash?: string | null
	transactions?: IUpdateInvoiceTransactionDetails[]
	protocol?: ProtocolEnumType
	onSuccessCallback?: () => void
	onCloseCallback?: () => void
}

const defaultProps: ITransactionSuccessOptional = {
	transactionHash: '',
	transactions: [],
	protocol: ProtocolEnumType.NEAR,
	onSuccessCallback: () => {},
	onCloseCallback: () => {},
}

interface ITransactionSuccess
	extends ITransactionSuccessRequired,
		ITransactionSuccessOptional {}

const TransactionSuccess: React.FC<ITransactionSuccess> = ({
	transactionHash = '',
	transactions = [],
	protocol = ProtocolEnumType.NEAR,
	transactionTitles,
	transactionDetails,
	onSuccessCallback = () => {},
	onCloseCallback = () => {},
}) => {
	const [searchParams] = useSearchParams()

	const [isTransactionSuccess, setIsTransactionSuccess] = useState(true)

	useEffectOnce(() => {
		onSuccessCallback()
	})

	const onCloseTransactionModal = () => {
		searchParams.delete('transactionHashes')

		setIsTransactionSuccess(false)

		onCloseCallback()
	}

	const commonTransactions = protocol === ProtocolEnumType.NEAR || transactionHash
		? transactionHash?.split(',').map((hash) => ({
		hash,
		amount: 0,
		token: WalletAccountBalanceToken.NOT_DEFINED
	})) ?? []
		: transactions

	return (
		<Modal
			isOpen={isTransactionSuccess}
			onClose={onCloseTransactionModal}
			className="transaction-success without-padding"
			component={
				<>
					<div className="success-dialog__icon__wrapper">
						<span className="success-dialog__icon-background-small" />
						<span className="success-dialog__icon-background-big" />
					</div>
					<IconButton
						onClick={onCloseTransactionModal}
						className="success-dialog__button-clear"
					>
						<Clear fontSize="large" />
					</IconButton>
					<div className="text-center">
						<H2>Transaction Successful</H2>
						<ExtraSmallParagraph className="success-dialog__note">
							Please note that transaction details may appear later
						</ExtraSmallParagraph>
					</div>
					<TransactionDetails
						transactionDetailsMapper={createTransactionDetails(
							transactionTitles,
							transactionDetails
						)}
					/>
					<div className="flex flex-column justify-center m-b">
						{commonTransactions.map((transaction, index) => {
								const endingStr = transaction.amount && transaction.token
									? ` (${transaction.amount} ${tokenSymbols[transaction.token]})`
									: ''

								return (
									<div
										className={`${index > 0 ? 'm-t-half' : ''} text-center`}
										key={transaction.hash}
									>
										<ViewOnExplorer
											key={transaction.hash}
											protocol={protocol}
											transactionHash={transaction.hash}
										>
											{commonTransactions.length > 1
												? `View transaction #${index + 1}${endingStr}`
												: 'View on Explorer'
											}
										</ViewOnExplorer>
									</div>
								);
							}
						)}
					</div>
				</>
			}
		/>
	)
}

TransactionSuccess.defaultProps = defaultProps

export default TransactionSuccess
