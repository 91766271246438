import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import { useWalletSelector } from 'context/WalletSelectorContext';
import { useDispatch } from 'react-redux';
import { IUserWallet } from 'redux/user/types';
import { deleteWalletDataRequest, updateWalletDataRequest } from 'redux/wallet/reducer';
import { Button as ShareButton } from 'share'
import buttonSettings from 'constants/buttonSettings'
import React from 'react';
import { Popover } from 'share/index';
import { DeleteForever, ToggleOn, ToggleOff } from '@mui/icons-material';

const { ROUNDED, SMALL_H, CONTAINED, TEXT, MARGIN_LEFT, MARGIN_TOP } = buttonSettings


interface WalletsActionProps {
	wallet: IUserWallet
}

const WalletsActionMenu: React.FC<WalletsActionProps> = ({ wallet }) => {
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null)
	const open = Boolean(anchorEl)

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const deleteWallet = () => {
		dispatch(
			deleteWalletDataRequest(wallet.id)
		)

		handleClose()
	}

	const toggleActive = () => {
		if (wallet) {
			dispatch(
				updateWalletDataRequest({
					...wallet,
					active: !wallet.active,
				})
			)

			handleClose()
		}
	}

	return (
		<>
			<Button
				className={`${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`}
				variant={`${CONTAINED}`}
				onClick={handleOpen}
			>
				<MoreHorizIcon />
			</Button>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorEl={anchorEl}
				open={open}
				onClosePopover={handleClose}
				showArrow={false}
				className="m-t-quarter"
			>
				<div className="p-b-half p-l-half p-r flex-column align-items-start">
					<ShareButton
						type="button"
						buttonStyle={`${MARGIN_TOP} without-uppercase`}
						variant={TEXT}
						onClick={toggleActive}
					>
						{wallet.active ? (
							<>
								<ToggleOff className="m-r-half" />
								Deactivate
							</>
						) : (
							<>
								<ToggleOn className="m-r-half" />
								Activate
							</>
						)}
					</ShareButton>
					<ShareButton
						type="button"
						buttonStyle={`${MARGIN_TOP} without-uppercase`}
						variant={TEXT}
						onClick={deleteWallet}
					>
						<DeleteForever className="m-r-half" />
						Delete
					</ShareButton>
				</div>
			</Popover>
		</>
	)
}

export default WalletsActionMenu
