import React from 'react'
import './index.scss'

interface ArticleRequired {
	children: React.ReactNode
}

interface ArticleOptional {
	className?: string
}

interface IArticleProps extends ArticleRequired, ArticleOptional {}

const defaultProps: ArticleOptional = {
	className: '',
}

const Article: React.FC<IArticleProps> = ({ className, children }) => (
	<article className={`article-wrapper ${className}`}>{children}</article>
)

Article.defaultProps = defaultProps

export default Article
