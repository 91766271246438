import React from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoiceEditStatusRequest } from 'redux/invoice/reducer';
import { IInvoice } from 'redux/invoice/types';
import { Button } from 'share'

interface EditInvoiceBtnI {
	children: React.ReactNode,
	buttonStyle: string,
	variant: 'text' | 'contained' | 'outlined',
	invoice: IInvoice
}
const EditInvoiceButton: React.FC<EditInvoiceBtnI> = ({children, buttonStyle, variant, invoice} ) => {
	const dispatch = useDispatch();

	const clickHandler = () => {
		dispatch(updateInvoiceEditStatusRequest({
			...invoice,
			status: 'draft'
		}))
	}

	return (
		<Button
			onClick={clickHandler}
			type="button"
			variant={variant}
			buttonStyle={buttonStyle}
		>
			{children}
		</Button>
	)
}

export default EditInvoiceButton;
