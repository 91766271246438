import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import { revokeUserInviteRequest } from 'redux/user/reducer'

import { Button, H2 } from 'share'
import { Dialog, DialogContent } from '@mui/material'

const { SECONDARY, SMALL_H, MARGIN_LEFT, CONTAINED, PRIMARY, MEDIUM_H, FULL_W } = buttonSettings

interface IRevokeInviteButtonRequired {
	children: React.ReactNode
	email: string
}

interface IRevokeInviteButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IRevokeInviteButtonOptional = {
	buttonStyle: `${SECONDARY} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IRevokeInviteButton
	extends IRevokeInviteButtonRequired,
		IRevokeInviteButtonOptional {}

const RevokeInviteButton: React.FC<IRevokeInviteButton> = ({
	children,
	email,
	buttonStyle = `${SECONDARY} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

	const onConfirmationClose = () => {
		setIsConfirmationOpen(false)
	}

	const onConfirmationOpen = () => {
		setIsConfirmationOpen(true)
	}

	const onRevokeConfirm = () => {
		dispatch(
			revokeUserInviteRequest({
				email,
			})
		)

		onConfirmationClose()
	}

	return (
		<>
			<Button
				type="button"
				buttonStyle={buttonStyle}
				variant={variant}
				onClick={onConfirmationOpen}
			>
				{children}
			</Button>

			<Dialog open={isConfirmationOpen} onClose={onConfirmationClose}>
				<DialogContent>
					<H2 className="m-b-half text-center">
						Confirm the invitation revoke
					</H2>
					<p className="text-medium text-dark text-center">
						Are you sure you want to revoke invitation for&nbsp;
						<span className="text-medium text-semi-bold">{email}</span>?
						<br/>
					</p>

					<div className="flex flex-1 m-t">
						<Button
							onClick={onConfirmationClose}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${SECONDARY} ${MEDIUM_H}`}
						>
							Cancel
						</Button>
						<Button
							onClick={onRevokeConfirm}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${PRIMARY} ${MEDIUM_H} ${MARGIN_LEFT}`}
						>
							Revoke
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

RevokeInviteButton.defaultProps = defaultProps

export default React.memo(RevokeInviteButton)
