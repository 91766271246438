import { ITransactionTitles } from 'constants/transactionTitles'

type ITransactionDetail = string | React.ReactNode

export interface ICreateTransactionDetails {
	(
		transactionTitles: ITransactionTitles,
		transactionDetails: { [key: string]: ITransactionDetail }
	): ITransactionDetailsMapper
}

export type ITransactionDetailsMapper = {
	title: string
	detail: ITransactionDetail
}[]

export const createTransactionDetails: ICreateTransactionDetails = (
	transactionTitles,
	transactionDetails
) =>
	transactionTitles.map(({ title, key }) => ({
		title,
		detail: transactionDetails[key],
	}))
