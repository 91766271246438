import React from 'react';
import { Button } from 'share/index';
import buttonStyles from 'constants/buttonSettings';

const { PRIMARY, MEDIUM_H, CONTAINED } = buttonStyles

const ErrorFallback = () => {
	const clickHandler = () => window.location.reload()
	return (
		<div className="flex flex-column align-items-center section-wrapper error-fallback">
			<h1>Oops!</h1>
			<p className="m-b-half text-center">Something went wrong on this page. You can contact support anytime using <a className="text-bold" href="mailto:support@geekpay.co">support@geekpay.co</a></p>
			<Button
				type="button"
				variant={CONTAINED}
				buttonStyle={`${PRIMARY} ${MEDIUM_H}`}
				onClick={clickHandler}
			>
				Reload page
			</Button>
		</div>
	)

}
 export default ErrorFallback;
