import React from 'react';
import './index.scss';

export const walletStatuses = {
	active: {
		title: 'Active',
		className: 'active',
	},
	inactive: {
		title: 'Inactive',
		className: 'inactive',
	},
}

interface IWalletStatus {
	status: 'active' | 'inactive'
	className?: string
	textClassName?: string
}

const WalletStatus: React.FC<IWalletStatus> = ({
	status,
	className = '',
	textClassName = '',
}) => (
	<div className={`flex align-items-center ${className}`}>
		<span className={`status_${walletStatuses[status].className} ${textClassName}`}>
			{walletStatuses[status].title}
		</span>
	</div>
)

WalletStatus.defaultProps = {
	className: '',
	textClassName: '',
}

export default WalletStatus;
