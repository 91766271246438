import React from 'react'

import logo from './logo.svg'

import './index.scss'

interface ILogoRequired {
	logoStyle: string
}

interface ILogoOptional {
	width?: number
	height?: number
}

interface ILogo extends ILogoRequired, ILogoOptional {}

const defaultProps: ILogoOptional = {
	width: undefined,
	height: undefined,
}

const Logo: React.FC<ILogo> = ({ logoStyle, width, height }) => (
	<img
		src={logo}
		className={logoStyle}
		alt="GeekPay logo"
		width={width}
		height={height}
	/>
)

Logo.defaultProps = defaultProps

export default Logo
