import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'

import { Button, H2 } from 'share'
import { Dialog, DialogContent } from '@mui/material'
import { ITeamRoleUser, RoleEnumType } from 'redux/user/types'
import { getCustomerRecipientTitleAvatar } from 'utils/invoiceUtils'
import { selectUser } from 'redux/user/selectors'
import { deleteMyUserRequest } from 'redux/myUser/reducer'
import { deleteTeamMemberRequest } from 'redux/memberList/reducer'
import { getUserRequest } from 'redux/user/reducer'
import { useUpdateEffect } from 'react-use'
import { selectMemberList } from 'redux/memberList/selectors'

const { SECONDARY, SMALL_H, MARGIN_LEFT, CONTAINED, ROUNDED, PRIMARY, MEDIUM_H, FULL_W } = buttonSettings

interface IDeleteButtonRequired {
	children: React.ReactNode
	myUser: ITeamRoleUser
}

interface IDeleteButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
	isMemberList?: boolean
}

const defaultProps: IDeleteButtonOptional = {
	buttonStyle: `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
	isMemberList: false,
}

interface IDeleteButton extends IDeleteButtonRequired, IDeleteButtonOptional {}

const DeleteUserButton: React.FC<IDeleteButton> = ({
	children,
	myUser,
	buttonStyle = `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
	isMemberList = false,
}) => {
	const dispatch = useDispatch()
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

	const { user } = useSelector(selectUser)
	const { isDeleteTeamMemberSuccess } = useSelector(selectMemberList)

	const { title } = getCustomerRecipientTitleAvatar(
		myUser,
		myUser.team,
		user.role === RoleEnumType.COMPANY
			? RoleEnumType.CONTRACTOR
			: RoleEnumType.COMPANY
	)

	const deleteRequest = isMemberList
		? deleteTeamMemberRequest
		: deleteMyUserRequest

	const onConfirmationClose = () => {
		setIsConfirmationOpen(false)
	}

	const onConfirmationOpen = () => {
		setIsConfirmationOpen(true)
	}

	const onDeleteConfirm = () => {
		dispatch(
			deleteRequest({
				id: myUser.team_role_id,
			})
		)

		onConfirmationClose()
	}

	useUpdateEffect(() => {
		if (isDeleteTeamMemberSuccess && isMemberList && myUser.id === user.id) {
			dispatch(
				getUserRequest()
			)
		}
	}, [isDeleteTeamMemberSuccess])

	return (
		<>
			<Button
				type="button"
				buttonStyle={buttonStyle}
				variant={variant}
				onClick={onConfirmationOpen}
			>
				{children}
			</Button>

			<Dialog open={isConfirmationOpen} onClose={onConfirmationClose}>
				<DialogContent>
					<H2 className="m-b-half text-center">
						Confirm removal
					</H2>
					<p className="text-medium text-dark text-center">
						Are you sure you want to remove&nbsp;
						<span className="text-medium text-semi-bold">{title}</span>?
						<br/>
					</p>

					<div className="flex flex-1 m-t">
						<Button
							onClick={onConfirmationClose}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${SECONDARY} ${MEDIUM_H}`}
						>
							Cancel
						</Button>
						<Button
							onClick={onDeleteConfirm}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${PRIMARY} ${MEDIUM_H} ${MARGIN_LEFT}`}
						>
							Remove
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

DeleteUserButton.defaultProps = defaultProps

export default React.memo(DeleteUserButton)
