import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { onOpenNotification } from 'redux/notification/reducer';

import H2 from 'share/H2'
import Modal from 'share/Modal'

import TransactionDetails from 'share/TransactionDetails'

import { createTransactionDetails } from 'share/TransactionDetails/helpers/createTransactionDetails'

import './index.scss'
import { TRON_REFRESH_TIMEOUT } from 'utils/userUtils';

interface ITransactionProgressRequired {
	transactionTitles: Array<{ title: string; key: string }>
	transactionDetails: { [key: string]: string | React.ReactNode }
}

interface ITransactionProgressOptional {
	onSuccessCallback?: () => void
	onCloseCallback?: () => void
}

const defaultProps: ITransactionProgressOptional = {
	onSuccessCallback: () => {},
	onCloseCallback: () => {},
}

interface ITransactionProgress
	extends ITransactionProgressRequired,
		ITransactionProgressOptional {}

const TransactionProgress: React.FC<ITransactionProgress> = ({
	transactionTitles,
	transactionDetails,
	onSuccessCallback = () => {},
	onCloseCallback = () => {},
}) => {
	const dispatch = useDispatch()

	useEffectOnce(() => {
		onSuccessCallback()
	})

	const onCloseTransactionModal = () => {
		dispatch(
			onOpenNotification({
				message: 'Please wait until pending transaction complete',
				notificationType: 'info',
			})
		)

		setTimeout(() => {
			dispatch(
				onOpenNotification({
					message: 'Do not forget to check your wallet for required approvals',
					notificationType: 'warning',
				})
			)
		}, TRON_REFRESH_TIMEOUT)

		onCloseCallback()
	}

	return (
		<Modal
			isOpen
			onClose={onCloseTransactionModal}
			className="transaction-success without-padding"
			component={
				<>
					<div className="transaction-pending__wrapper">
						<div className="article-loader" />
					</div>
					<div className="text-center">
						<H2 className="m-b-quarter">Transaction Pending</H2>
						<p className="transaction-pending__note text-dark-grey">
							Please <span className="text-semi-bold text-darkest-grey">keep the page open</span> until the transaction is done
						</p>
					</div>
					<TransactionDetails
						transactionDetailsMapper={createTransactionDetails(
							transactionTitles,
							transactionDetails
						)}
					/>
				</>
			}
		/>
	)
}

TransactionProgress.defaultProps = defaultProps

export default TransactionProgress
