import { CircularProgress } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import { downloadInvoicePdfRequest } from 'redux/invoice/reducer'
import { selectInvoice } from 'redux/invoice/selectors'

import { Button } from 'share'

const { SECONDARY, MEDIUM_H, CONTAINED, MARGIN_LEFT } = buttonSettings

interface IGenerateCsvButtonRequired {
	children: React.ReactNode
}

interface IGenerateCsvButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IGenerateCsvButtonOptional = {
	buttonStyle: `${SECONDARY} ${MEDIUM_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IGenerateCsvButton
	extends IGenerateCsvButtonRequired,
		IGenerateCsvButtonOptional {}

const GeneratePdfButton: React.FC<IGenerateCsvButton> = ({
	children,
	buttonStyle = `${SECONDARY} ${MEDIUM_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const { currentInvoice, isDownloadInvoicePdfInProgress } =
		useSelector(selectInvoice)

	const onClick = () => {
		const { id, invoice_id, status } = currentInvoice

		dispatch(downloadInvoicePdfRequest({ id, invoice_id, status }))
	}

	return (
		<Button
			type="button"
			buttonStyle={buttonStyle}
			variant={variant}
			onClick={onClick}
			disabled={isDownloadInvoicePdfInProgress}
		>
			<div className="flex justify-center align-items-center btn-font-keeper">
				{isDownloadInvoicePdfInProgress ? (
					<>
						<span className="btn-font-keeper m-r-half">Preparing pdf...</span>
						<CircularProgress size={20} thickness={5} />
					</>
				) : children }
			</div>
		</Button>
	)
}

GeneratePdfButton.defaultProps = defaultProps

export default React.memo(GeneratePdfButton)
