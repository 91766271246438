import React from 'react'

import './index.scss'

interface IExtraSmallParagraphRequired {
	children: React.ReactNode
}

interface IExtraSmallParagraphOptional {
	className?: string
}

const defaultProps: IExtraSmallParagraphOptional = {
	className: '',
}
interface IExtraSmallParagraphProps
	extends IExtraSmallParagraphRequired,
		IExtraSmallParagraphOptional {}

const ExtraSmallParagraph: React.FC<IExtraSmallParagraphProps> = ({
	children,
	className = '',
}) => <p className={`extra-small-paragraph ${className}`}>{children}</p>

ExtraSmallParagraph.defaultProps = defaultProps

export default ExtraSmallParagraph
