import React from 'react'

import { Chip } from '@mui/material'

import { IInvoice } from 'redux/invoice/types'
import { ITag } from 'redux/tag/types'

import './index.scss'

interface ITagsProps {
	invoice: IInvoice
	showTags: string[]
	setShowTags: React.Dispatch<React.SetStateAction<string[]>>
	filters: {
		tags?: ITag[]
	}
	filterByTag: (tag: ITag) => void
}

const Tags: React.FC<ITagsProps> = ({
	invoice,
	showTags,
	setShowTags,
	filters,
	filterByTag,
}) =>
	invoice.tags?.length ? (
		<>
			<Chip
				variant="outlined"
				label={invoice.tags[0].title}
				// @ts-ignore
				onClick={() => filterByTag(invoice.tags[0])}
				className={`tag-chip ${
					filters.tags?.some(
						// @ts-ignore
						(tag) => tag.id === invoice.tags[0].id
					)
						? 'tag-chip_selected'
						: 'tag-chip_correct'
				}`}
			/>
			{invoice.tags?.length > 1 && (
				<Chip
					label={`+${invoice.tags.length - 1} more`}
					className={`tag-chip ${
						showTags.includes(invoice.id) ? 'd-none' : ''
					}`}
					onClick={() => setShowTags((prevState) => [...prevState, invoice.id])}
				/>
			)}
			{invoice.tags?.slice(1).map((tag) => (
				<Chip
					key={tag.title}
					variant="outlined"
					label={tag.title}
					onClick={() => filterByTag(tag)}
					className={`tag-chip tag-chip_correct ${
						showTags.includes(invoice.id) ? '' : 'd-none'
					} ${
						filters.tags?.some((item) => item.id === tag.id)
							? 'tag-chip_selected'
							: 'tag-chip_correct'
					}`}
				/>
			))}
			{invoice.tags?.length > 1 && (
				<Chip
					label="hide"
					className={`tag-chip ${
						showTags.includes(invoice.id) ? '' : 'd-none'
					}`}
					onClick={() =>
						setShowTags((prevState) =>
							prevState.filter((value) => value !== invoice.id)
						)
					}
				/>
			)}
		</>
	) : null

export default Tags
