import { SvgIconComponent } from '@mui/icons-material'
import React from 'react'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'

import {
	IBlockchainGroup,
	IBscWalletGroup,
	ICurrencyGroup,
	IRoleGroup,
	ISubscriptionGroup, IWalletGroup,
} from 'utils/wallets'

import './index.scss'

interface ToggleGroupButtonRequired {
	onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void
	groupList: IBlockchainGroup | ICurrencyGroup | IRoleGroup | IBscWalletGroup | ISubscriptionGroup | IWalletGroup
}

interface ToggleGroupButtonOptional {
	value?: string | null
	label?: string
	orientation?: 'horizontal' | 'vertical' | undefined
	className?: string
	Icon?: SvgIconComponent
}

interface IToggleGroupButtonProps
	extends ToggleGroupButtonRequired,
		ToggleGroupButtonOptional {}

const defaultProps: ToggleGroupButtonOptional = {
	className: 'text-semi-dark text-small m-b-quarter',
	Icon: undefined,
	label: '',
	value: '',
	orientation: 'horizontal',
}

const ToggleGroupButton: React.FC<IToggleGroupButtonProps> = ({
	groupList,
	label = '',
	orientation = 'horizontal',
	value = '',
	onChange,
	className,
	Icon,
}) => (
	<>
		{label ? (
			<p className={className}>
				{Icon ? (
					<>
						<Icon />
						&nbsp;
					</>
				) : null}
				{label}
			</p>
		) : null}
		<ToggleButtonGroup
			exclusive
			fullWidth={orientation === 'vertical'}
			orientation={orientation}
			color="primary"
			value={value}
			onChange={onChange}
		>
			{groupList
				? groupList.map((group) => (
						<ToggleButton
							key={group.label ?? group.value ?? ''}
							value={group.value ?? ''}
							aria-label={group.label}
							className={`${
								orientation === 'vertical' ? 'align-items-start' : ''
							}`}
						>
							{group.icon
								? group.tooltip || !group.label ? (
									<Tooltip
										title={group.tooltip ?? group.value}
										classes={{ tooltip: 'capitalize' }}
										placement="top"
										arrow
									>
										<img
											className={`toggle-img aspect-ratio-1 ${
												group.label ? 'img-spacing' : ''
											}`}
											src={group.icon}
											alt={group.label}
											width={18}
										/>
									</Tooltip>
								) : (
									<img
										className={`toggle-img aspect-ratio-1 pointer-events-none ${
											group.label ? 'img-spacing' : ''
										}`}
										src={group.icon}
										alt={group.label}
										width={18}
									/>
							) : null}
							{group.label ? (
								<span className="pointer-events-none without-uppercase text-semi-bold text-small">
									{group.label}
								</span>
							) : null}
						</ToggleButton>
				  ))
				: null}
		</ToggleButtonGroup>
	</>
)

ToggleGroupButton.defaultProps = defaultProps

export default ToggleGroupButton
