import React from 'react'
import { useDispatch } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import { cloneInvoicesRequest } from 'redux/invoice/reducer'

import { Button } from 'share'

const { ROUNDED, SMALL_H, MARGIN_LEFT, CONTAINED } = buttonSettings

interface ICloneInvoiceButtonRequired {
	children: React.ReactNode
	invoiceIds: string[]
}

interface ICloneInvoiceButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: ICloneInvoiceButtonOptional = {
	buttonStyle: `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface ICloneInvoiceButton extends ICloneInvoiceButtonRequired, ICloneInvoiceButtonOptional {}

const CloneInvoiceButton: React.FC<ICloneInvoiceButton> = ({
	children,
	invoiceIds,
	buttonStyle = `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const onClick = () => {
		dispatch(
			cloneInvoicesRequest({
				invoiceIds,
			})
		)
	}

	return (
		<Button
			type="button"
			buttonStyle={buttonStyle}
			variant={variant}
			onClick={onClick}
			disabled={invoiceIds.length === 0}
		>
			{children}
		</Button>
	)
}

CloneInvoiceButton.defaultProps = defaultProps

export default React.memo(CloneInvoiceButton)
