import React from 'react'

import './index.scss'

interface H2Required {
	children: React.ReactNode
}

interface H2Optional {
	className?: string
}

interface IH2Props extends H2Required, H2Optional {}

const defaultProps: H2Optional = {
	className: '',
}

const H2: React.FC<IH2Props> = ({ className, children }) => (
	<h2 className={`h2 ${className}`}>{children}</h2>
)

H2.defaultProps = defaultProps

export default H2
