import React from 'react'
import { useDispatch } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import {
	archiveInvoicesRequest,
} from 'redux/invoice/reducer'

import { Button } from 'share'

const { ROUNDED, SMALL_H, MARGIN_LEFT, CONTAINED } = buttonSettings

interface IArchiveInvoiceButtonRequired {
	children: React.ReactNode
	invoiceIds: string[]
	archive: boolean
}

interface IArchiveInvoiceButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IArchiveInvoiceButtonOptional = {
	buttonStyle: `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IArchiveInvoiceButton extends IArchiveInvoiceButtonRequired, IArchiveInvoiceButtonOptional {}

const ArchiveInvoiceButton: React.FC<IArchiveInvoiceButton> = ({
	archive,
	children,
	invoiceIds,
	buttonStyle = `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()

	const onClick = () => {
		dispatch(
			archiveInvoicesRequest({
				invoiceIds,
				archive
			})
		)
	}

	return (
		<Button
			type="button"
			buttonStyle={buttonStyle}
			variant={variant}
			onClick={onClick}
			disabled={invoiceIds.length === 0}
		>
			{children}
		</Button>
	)
}

ArchiveInvoiceButton.defaultProps = defaultProps

export default React.memo(ArchiveInvoiceButton)
