import React from 'react';
import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import buttonSettings from 'constants/buttonSettings'
import {
	DeleteForever,
	PersonRemove, Replay,
} from '@mui/icons-material'
import { DeleteUserButton, Popover, RevokeInviteButton, ResendInviteButton } from 'share'
import { ITeamRoleUser } from 'redux/user/types'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/selectors'
import { useUpdateEffect } from 'react-use'

const { ROUNDED, SMALL_H, CONTAINED, TEXT, MARGIN_TOP, MARGIN_LEFT } = buttonSettings

interface IUserActionMenu {
	myUser: ITeamRoleUser
	className?: string
	isMemberList?: boolean
}

const defaultProps = {
	className: `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	isMemberList: false,
}

const UserActionMenu: React.FC<IUserActionMenu> = ({
	myUser,
	className,
	isMemberList = false
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
	const open = Boolean(anchorEl);

	const isMyUserRegistered = !!myUser.name

	const { isResendUserInviteLoading } = useSelector(selectUser)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useUpdateEffect(() => {
		if (anchorEl && (isResendUserInviteLoading)) {
			handleClose()
		}
	}, [isResendUserInviteLoading])

	return (
		<>
			<Button
				className={className}
				variant={`${CONTAINED}`}
				onClick={handleClick}
				disableRipple
			>
				<MoreHorizIcon />
			</Button>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorEl={anchorEl}
				open={open}
				onClosePopover={handleClose}
				showArrow={false}
				className="m-t-quarter"
			>
				<div className="p-b-half p-l-half p-r flex-column align-items-start">
					{isMyUserRegistered ? (
						<DeleteUserButton
							buttonStyle={`${MARGIN_TOP} without-uppercase`}
							variant={TEXT}
							myUser={myUser}
							isMemberList={isMemberList}
						>
							<DeleteForever className="m-r-half" />
							Remove
						</DeleteUserButton>
					) : (
						<>
							<RevokeInviteButton
								email={myUser.email}
								buttonStyle={`${MARGIN_TOP} without-uppercase`}
								variant={TEXT}
							>
								<PersonRemove className="m-r-half" />
								Revoke
							</RevokeInviteButton>
							<ResendInviteButton
								email={myUser.email}
								buttonStyle={`${MARGIN_TOP} without-uppercase`}
								variant={TEXT}
							>
								<Replay className="m-r-half" />
								Resend
							</ResendInviteButton>
						</>
					)}
				</div>
			</Popover>
		</>
	);
}

UserActionMenu.defaultProps = defaultProps

export default UserActionMenu
