import React, { useEffect, useState } from 'react'

import './index.scss'

interface ICheckBoxRequired {
	className: string
}

interface ICheckBoxOptional {
	id?: null | string
	checked?: boolean
	disabled?: boolean
	onClick?: (isChecked: boolean) => void
	onChange?: (id: string) => void
	children?: React.ReactNode
}

const defaultProps: ICheckBoxOptional = {
	id: null,
	checked: false,
	disabled: false,
	onClick: () => {},
	onChange: () => {},
}

interface ICheckBoxProps extends ICheckBoxRequired, ICheckBoxOptional {}

const CheckBox: React.FC<ICheckBoxProps> = ({
	className,
	children,
	onChange = () => {},
	onClick = () => {},
	id = null,
	checked = false,
	disabled = false,
}) => {
	const [isChecked, setIsChecked] = useState(false)

	const handleChange = () => {
		onClick(!isChecked)

		if (id !== null) onChange(id)
	}

	useEffect(() => {
		setIsChecked(Boolean(checked))
	}, [checked])

	return (
		<label htmlFor="checkbox" className="checkbox-container">
			<input
				type="checkbox"
				name="checkbox"
				className={`checkbox ${className}`}
				checked={isChecked}
				onChange={handleChange}
				disabled={disabled}
			/>
			{children}
		</label>
	)
}

CheckBox.defaultProps = { ...defaultProps }

export default React.memo(CheckBox)
