import React from 'react'

import { IStatusType } from 'redux/invoice/types'

import './index.scss'
import {
	HelpOutlineRounded,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/selectors'
import { RoleEnumType } from 'redux/user/types'
import {
	PENDING_INVOICE_COMPANY_MSG,
	PENDING_INVOICE_CONTRACTOR_MSG,
} from 'utils/userUtils'

export const invoiceStatuses = {
	draft: {
		title: 'Draft',
		className: 'draft',
	},
	pending: {
		title: 'Pending',
		className: 'pending',
	},
	readyToPay: {
		title: 'Awaiting payment',
		className: 'ready-to-pay',
	},
	paid: {
		title: 'Paid',
		className: 'paid',
	},
	closed: {
		title: 'Closed',
		className: 'closed',
	},
	void: {
		title: 'Void',
		className: 'void',
	},
	inReview: {
		title: 'In review',
		className: 'in-review',
	},
}

interface IInvoiceStatusRequired {
	status: IStatusType
}

interface IInvoiceStatusOptional {
	className?: string
	title?: string
	hidePendingTooltip?: boolean
}

const defaultProps: IInvoiceStatusOptional = {
	className: '',
	title: '',
	hidePendingTooltip: false,
}

interface IInvoiceStatusProps
	extends IInvoiceStatusRequired,
		IInvoiceStatusOptional {}

const Status: React.FC<IInvoiceStatusProps> = ({
	status,
	className = '',
	title = '',
	hidePendingTooltip = false,
}) => {
	const { user } = useSelector(selectUser)

	return (
		<div className="flex align-items-center">
			<span
				className={`status_${invoiceStatuses[status].className} ${className}`}>
				{title || invoiceStatuses[status].title}
			</span>
			{!hidePendingTooltip && status === 'pending' ? (
				<>
					&nbsp;
					<Tooltip
						title={user.role === RoleEnumType.CONTRACTOR
							? PENDING_INVOICE_CONTRACTOR_MSG
							: PENDING_INVOICE_COMPANY_MSG
						}
						placement="top"
						arrow
						enterDelay={0}
						disableTouchListener
						disableFocusListener
					>
						<HelpOutlineRounded className="invoice-status-help-icon" color="primary" />
					</Tooltip>
				</>
			) : null}
		</div>
	)
}

Status.defaultProps = defaultProps

export default Status
