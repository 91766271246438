import { IconButton } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { onOpenNotification } from 'redux/notification/reducer'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import './index.scss'
import { getFormattedAccount } from 'utils/userUtils'

interface ITextCopyRequired {
	text: string
}

interface ITextCopyOptional {
	notificationMessage?: string
	className?: string
	shorten?: boolean
}

const defaultProps = {
	notificationMessage: 'Wallet address copied',
	className: '',
	shorten: true,
}

interface ITextCopyProps extends ITextCopyRequired, ITextCopyOptional {}

const TextCopy = ({
	text,
	notificationMessage = 'Wallet address copied',
	className = 'text-medium',
	shorten = true,
}: ITextCopyProps) => {
	const dispatch = useDispatch()

	const onCopyWallet = () => {
		navigator.clipboard.writeText(text).finally()

		if (notificationMessage) {
			dispatch(
				onOpenNotification({
					message: notificationMessage,
					notificationType: 'success',
				})
			)
		}
	}

	return (
		<span
			className={`text-copy__wrapper flex ${
				shorten ? 'ws-nowrap' : ''
			} ${className}`}
		>
			{shorten ? getFormattedAccount(text) : text}
			<IconButton className="text-copy__copy-button" onClick={onCopyWallet}>
				<FileCopyOutlinedIcon />
			</IconButton>
		</span>
	)
}

TextCopy.defaultProps = defaultProps

export default TextCopy
