import React from 'react'
import { Button as ButtonMUI } from '@mui/material'

import './index.scss'

interface IButtonRequired {
	children: React.ReactNode
	type: 'submit' | 'reset' | 'button'
	variant: 'text' | 'contained' | 'outlined'
}

interface IButtonOptional {
	disabled?: boolean
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	buttonStyle?: string
	tabIndex?: number
}

const defaultProps: IButtonOptional = {
	disabled: false,
	tabIndex: 0,
	onClick: () => {},
	buttonStyle: '',
}

interface IButtonProps extends IButtonRequired, IButtonOptional {}

const Button: React.ForwardRefExoticComponent<IButtonProps & React.RefAttributes<HTMLButtonElement | null>> = React.forwardRef(({
	type,
	buttonStyle,
	variant,
	disabled,
	onClick,
	children,
	tabIndex,
}: IButtonProps, ref) => (
	<ButtonMUI
		ref={ref}
		type={type}
		variant={variant}
		className={buttonStyle}
		disabled={disabled}
		onClick={onClick}
		sx={{
			'&:hover': {
				backgroundColor: 'transparent',
			},
		}}
		tabIndex={tabIndex}
		disableRipple
	>
		{children}
	</ButtonMUI>
))

Button.defaultProps = defaultProps

export default React.memo(Button)
