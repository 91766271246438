import React from 'react'

import './index.scss'

interface SectionRequired {
	children: React.ReactNode
}

interface SectionOptional {
	className?: string
}

interface ISectionProps extends SectionRequired, SectionOptional {}

const defaultProps: SectionOptional = {
	className: '',
}

const Section: React.FC<ISectionProps> = ({ className, children }) => (
	<section className={`section-wrapper ${className}`}>{children}</section>
)

Section.defaultProps = defaultProps

export default Section
