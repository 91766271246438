import axios, { AxiosError, AxiosResponse } from 'axios'
import app_config from 'config/app_config'
import { defaults, isObject, isEmpty } from 'lodash'
// import paths from 'navigation/paths'
import { IApiMiddleware } from './types'

const baseURL = app_config.env.REACT_APP_BASE_URL

export default function authMiddleware({
	url = '/',
	method = 'POST',
	data = {},
	headers = {},
	transform = true,
	contentType = 'application/json',
	withCredentials = true,
}: IApiMiddleware): Promise<string> {
	defaults(headers, {
		Accept: 'application/json; charset=UTF-8',
		'Content-Type': `${contentType}; charset=UTF-8`,
	})

	return axios({
		baseURL,
		method,
		url,
		headers,
		withCredentials,
		params: method === 'GET' && isObject(data) && !isEmpty(data) ? data : {},
		data: transform ? JSON.stringify(data) : data,
	})
		.then((resp: AxiosResponse) => resp.data)
		.catch((er: AxiosError<any>) => {
			const error = er?.response?.data.message

			const spareMessage = 'Something went wrong'

			throw new Error(error || spareMessage)
		})
}
