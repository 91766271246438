import React from 'react'

import './index.scss'

interface IHelperTextRequired {
	children: React.ReactNode
}

interface IHelperTextOptional {
	className?: string
}

const defaultProps: IHelperTextOptional = {
	className: '',
}

interface IHelperTextProps extends IHelperTextRequired, IHelperTextOptional {}

const HelperText: React.FC<IHelperTextProps> = ({
	children,
	className = '',
}) => <p className={`hepler-text ${className}`}>{children}</p>

HelperText.defaultProps = defaultProps

export default HelperText
