import React from 'react'

import './index.scss'

interface H4Required {
	children: React.ReactNode
}

interface H4Optional {
	className?: string
}

interface IH4Props extends H4Required, H4Optional {}

const defaultProps: H4Optional = {
	className: '',
}

const H4: React.FC<IH4Props> = ({ className, children }) => (
	<h4 className={`h4 ${className}`}>{children}</h4>
)

H4.defaultProps = defaultProps

export default H4
