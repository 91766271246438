import React from 'react'
import { Modal as ModalMUI } from '@mui/material'
import { Section } from 'share'

import './index.scss'

interface IModalRequired {
	isOpen: boolean
	onClose: () => void
}

interface IModalOptional {
	className?: string
	component?: React.ReactElement
}

const defaultProps: IModalOptional = {
	className: '',
	component: undefined,
}

interface IModalProps extends IModalRequired, IModalOptional {}

const Modal: React.FC<IModalProps> = ({
	isOpen,
	onClose,
	component = undefined,
	className = '',
}) => (
	<ModalMUI onClose={onClose} open={isOpen} className="custom-modal">
		<>
			{' '}
			<Section className={`modal__section ${className}`}>{component}</Section>
		</>
	</ModalMUI>
)

Modal.defaultProps = defaultProps

export default Modal
