import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import buttonSettings from 'constants/buttonSettings'
import {
	deleteInvoicesRequest,
} from 'redux/invoice/reducer'
import { IInvoice } from 'redux/invoice/types'
import InvoiceId from 'pages/invoice/components/InvoiceId'

import { Button, H2 } from 'share'
import { Dialog, DialogContent } from '@mui/material'

const { SECONDARY, SMALL_H, MARGIN_LEFT, CONTAINED, ROUNDED, PRIMARY, MEDIUM_H, FULL_W } = buttonSettings

interface IDeleteButtonRequired {
	children: React.ReactNode
	invoices: Pick<IInvoice, 'id' | 'invoice_id' | 'custom_invoice_id'>[]
}

interface IDeleteButtonOptional {
	buttonStyle?: string
	variant?: 'text' | 'contained' | 'outlined'
}

const defaultProps: IDeleteButtonOptional = {
	buttonStyle: `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant: CONTAINED,
}

interface IDeleteButton extends IDeleteButtonRequired, IDeleteButtonOptional {}

const DeleteInvoiceButton: React.FC<IDeleteButton> = ({
	children,
	invoices,
	buttonStyle = `${ROUNDED} ${SMALL_H} ${MARGIN_LEFT}`,
	variant = CONTAINED,
}) => {
	const dispatch = useDispatch()
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

	const onConfirmationClose = () => {
		setIsConfirmationOpen(false)
	}

	const onConfirmationOpen = () => {
		setIsConfirmationOpen(true)
	}

	const onDeleteConfirm = () => {
		dispatch(
			deleteInvoicesRequest({
				invoiceIds: invoices.map(invoice => invoice.id),
			})
		)

		onConfirmationClose()
	}

	return (
		<>
			<Button
				type="button"
				buttonStyle={buttonStyle}
				variant={variant}
				onClick={onConfirmationOpen}
				disabled={invoices.length === 0}
			>
				{children}
			</Button>

			<Dialog open={isConfirmationOpen} onClose={onConfirmationClose}>
				<DialogContent>
					<H2 className="m-b-half text-center">
						Confirm the deletion
					</H2>
					<p className="text-medium text-dark text-center">
						Are you sure you want to delete Invoice{invoices.length > 1 ? 's' : ''}&nbsp;
						{invoices.map((invoice, index) => (
							<React.Fragment key={invoice.id}>
								#<InvoiceId
									className="text-semi-bold"
									invoice={invoice}
									hideCopyIcon
								/>
								{(invoices.length - 1) === index ? '' : ', '}
							</React.Fragment>
						))}?
						<br/>
						This action can’t be undone.
					</p>

					<div className="flex flex-1 m-t">
						<Button
							onClick={onConfirmationClose}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${SECONDARY} ${MEDIUM_H}`}
						>
							Cancel
						</Button>
						<Button
							onClick={onDeleteConfirm}
							type="button"
							variant={CONTAINED}
							buttonStyle={`${FULL_W} ${PRIMARY} ${MEDIUM_H} ${MARGIN_LEFT}`}
						>
							Delete
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

DeleteInvoiceButton.defaultProps = defaultProps

export default React.memo(DeleteInvoiceButton)
